




























































































































































































































































































































.card p{
  max-width: 320px;
  display: block;
  margin: 0 auto 20px auto;
}
