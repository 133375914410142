<template>
  <div>
      <div class="row">
          <div class="col-sm-6 col-lg-3">
            <div class="card rounded-lg p-3">
              <h3 class="text-center">ReplyEngine</h3>
              <p>Connect your customers Google Business Profile and automatically respond to Google reviews in real time using AI.</p>
              <p>
                <a href="https://replyengine.io/replay" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
              </p>
              <div class="text-center">
                <button v-if="hasReplyEnginePlan" disabled type="button" class="btn btn-success"><i class="uil uil-check-square mr-2" />Purchased</button>
                <button v-else class="btn btn-primary" type="button"
                        @click="showSubscriptions('ReplyEngine')">Purchase</button>
              </div>
            </div>
          </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">TapEngine</h3>
            <p>Collect massive reviews for your customers using NFC cards. Track all employees & new Google reviews.</p>
            <p>
              <a href="https://tapengine.io/replay" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasTapEnginePlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('TapEngine')">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">SocialReviews</h3>
            <p>Create images for Facebook and Instagram from your reviews and automatically post them to your account.</p>
            <p>
              <a href="https://getsocialreviews.com/private" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasSocialReviewPlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('SocialReview')">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">ReviewSurge</h3>
            <p>Maximize your review generation efforts by creating tailored text and email series to prompt feedback from your valued customers.</p>
            <p>
              <a href="https://reviewsurge.co/rs-replay" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasReviewSurgePlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('ReviewSurge')">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">Lead Machine</h3>
            <p>LeadMachine quickly builds direct mail campaigns with automated tools, real-time notifications, and detailed analytics.</p>
            <p>
              <a href="https://leadmachine.io/replay" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasLeadMachinePlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('LeadMachine')">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">SocialFusion</h3>
            <p>AI-generated daily social media posts with images, texts and hastags to auto post to Facebook and Instagram.</p>
            <p>
              <a href="https://socialfusionai.com/replay" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasSocialFusionPlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('SocialFusion')">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">InstaStream</h3>
            <p>Effortlessly showcase your Instagram feed on any website with a sleek, fully customizable widget.</p>
            <p>
              <a href="https://instastream.co" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasInstaStreamPlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('InstaStream')">Purchase</button>
            </div>
          </div>
        </div>
<!--        <div class="col-sm-6 col-lg-3">
          <div class="card rounded-lg p-3">
            <h3 class="text-center">RepuAgency</h3>
            <p>Embed Google Reviews on your customers website in minutes for the Ultimate Social Proof!</p>
            <p>
              <a href="https://repuagency.com" target="_blank" style="color:#1f7fc4" class="font-size-17 font-weight-bold">View Details <i class="uil uil-angle-double-right" /></a>
            </p>
            <div class="text-center">
              <button v-if="hasRepuAgencyPlan" class="btn btn-success" disabled type="button"><i class="uil uil-check-square mr-2" />Purchased</button>
              <button v-else class="btn btn-primary" type="button"
                      @click="showSubscriptions('RepuAgency')">Purchase</button>
            </div>
          </div>
        </div>-->
      </div>
      <b-modal v-model="showSubscriptionModal" size="lg" :hide-footer="true">
        <Subscription :type-filter="filterSubscriptions" />
      </b-modal>
      <b-modal v-model="needsAddress" :no-close-on-backdrop="!isImpersonating" :no-close-on-esc="true" :hide-header="true" :hide-footer="true">
        <h5 class="text-center mb-4">Please add your address to receive your TapEngine Cards.</h5>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid, touched }"
            ref="addressForm"
        >
          <form
              class="address-form"
              @submit.prevent="handleSubmit(updateAddress)"
          >
            <div class="row">
              <div class="col-12">
                <TextInput v-model="user.address.address" name="address.address" label="Address" type="text"  />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <TextInput v-model="user.address.city" name="address.city" label="City" type="text"  />
              </div>
              <div class="col-sm-6">
                <TextInput v-model="user.address.state" name="address.state" label="State" type="text"  />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <TextInput v-model="user.address.zip" name="address.zip" label="Zip" type="text"  />
              </div>
              <div class="col-sm-6">
                <ValidationProvider v-slot="{ failed, errors }" name="address.country">
                  <div class="form-group">
                    <label class="form-control-label"> Country</label>
                    <select v-model="user.address.country" name="address.country" class="form-control" :class="{ 'is-invalid': errors.length }">
                      <option v-for="country in Countries()" :key="country.id" :value="country.iso2">{{country.name}}</option>
                    </select>
                    <span v-show="errors.length" class="text-danger">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-button
                    variant="primary"
                    class="btn-dark-blue w-100"
                    :disabled="updatingAddress || (invalid && touched)"
                    type="submit"
                >
                  <b-spinner v-if="updatingAddress" small />
                  <span v-else>Submit</span>
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </b-modal>
      <b-modal v-model="showKeyAlert" content-class="keymodal">
        <p><strong>Important:</strong> Enter Your OpenAI API Key</p>
        <p>To ensure the software works properly, please enter your OpenAI API key.</p>
        <p>If you need any assistance, feel free to reach out to <a href="mailto:support@molomedia.com">support@molomedia.com</a></p>
        <b-button variant="primary" @click="gotoSettings">Goto Settings</b-button>
      </b-modal>
  </div>
</template>

<script>
import Subscription from "@components/Agency/Subscription.vue";
import Countries from "@src/countries";
export default {
  components: {
    Subscription,
  },
  data() {
    return {
      showSubscriptionModal:false,
      loadingSubscription:false,
      selectedPlan:null,
      addingCard:false,
      filterSubscriptions:null,
      updatingAddress:false,
      currentlyTyping:false,
      showKeyAlert: false
    }
  },

  computed: {
    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },
    needsAddress(){
      return this.user.has_te && (this.user?.address?.address === '' || this.user?.address?.city === '' || this.user?.address?.state === '' || this.user?.address?.zip === '' || this.user?.address?.country === '' || this.currentlyTyping);
    },
    user() {
      return this.$store.getters['auth/user']
    },
    hasReplyEnginePlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'ReplyEngine' || o.plan.planProducts.includes('ReplyEngine')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasTapEnginePlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'TapEngine' || o.plan.planProducts.includes('TapEngine')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasSocialReviewPlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'SocialReview' || o.plan.planProducts.includes('SocialReview')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasReviewSurgePlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'ReviewSurge' || o.plan.planProducts.includes('ReviewSurge')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasLeadMachinePlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'LeadMachine' || o.plan.planProducts.includes('LeadMachine')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasSocialFusionPlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'SocialFusion' || o.plan.planProducts.includes('SocialFusion')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasInstaStreamPlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'InstaStream' || o.plan.planProducts.includes('InstaStream')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    },
    hasRepuAgencyPlan(){
      return this.user?.user_plans && this.user.user_plans.filter(o => (o.plan.product_type === 'RepuAgency' || o.plan.planProducts.includes('RepuAgency')) && !o.past_due).length > 0 || (this.user.agency && this.user.agency.free_fee);
    }
  },
  watch:{
    'user.address': {
      handler(newAddress, oldAddress) {
        this.currentlyTyping = true;
      },
      deep: true
    }
  },
  mounted() {
    if(this.user.address && this.user.address.country === ''){
      this.user.address.country = 'US';
    }

    const hasSf = this.user?.user_plans && this.user.user_plans.filter(o => o.plan.product_type === 'SocialFusion' && !o.past_due).length || (this.user.agency && this.user.agency.free_fee);

    if(this.user.agency.settings.openai_api_key == null && hasSf){
      setTimeout(() => {
        this.showKeyAlert = true;
      }, 250);
    }
  },

  methods: {
    gotoSettings() {
      window.location.href = "/agency/settings";
    },
    updateAddress(){
      this.updatingAddress = true
      let params = {
        address:this.user?.address,
        setting_form:'address'
      }
      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.updatingAddress = false;
            this.currentlyTyping = false;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.addressForm.setErrors(err.response.data.errors);
            }
            this.updatingAddress = false
          })
    },
    Countries() {
      return Countries
    },
    showSubscriptions(which){
      this.filterSubscriptions = which;
      this.showSubscriptionModal = true;
    },
    subscription(plan) {
      this.selectedPlan = plan;
      if(this.user.default_payment_method){
        this.loadingSubscription = plan

        this.$store
            .dispatch('subscription/subscribe', plan)
            .then(() => {
              const that = this;
              setTimeout(function(){
                // TODO: Setup events instead of relying on a timeout
                that.loadingSubscription = null
                window.location.reload();
              },3000);
            })
            .catch(() => {
              this.loadingSubscription = null
            })
      }else{
        // this.showPaymentModal = true;
      }

    },
  },
}
</script>
<style lang="scss" scoped>
  .card p{
    max-width: 320px;
    display: block;
    margin: 0 auto 20px auto;
  }
</style>

<style>
.keymodal .modal-body{
    text-align: center !important;
    font-size: 17px !important;
    color: black !important;
  }
  .keymodal .modal-footer{
    display: none !important;
  }
  .keymodal .btn{
    font-weight: bold;
    font-size: 17px;
  }
</style>
